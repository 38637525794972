import React, { useEffect } from 'react'

import StepItem from './step-item'

import './index.css'

/*
0 - left to right one
1 - left to right two
2 - right to left one
3 - ending
*/
const svgPaths: any = {
  0: `
    <svg class="svg-stroke" width="100%" height="100%" viewBox="0 0 504 143" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path class="path" stroke="black" d="M16.8,0.7C6.4,20.8-3,51.8,2.7,79.9c2.8,14,9.4,27.3,21.5,38.1s29.7,19,54.5,22.9c62.1,9.8,104.9-12,147.6-36.3
      c42.6-24.3,85-50.9,146.2-50.9c97.9,0,127.9,51.9,130.6,77.7"/>
      <path class="mask" stroke="white" d="M16.8,0.7C6.4,20.8-3,51.8,2.7,79.9c2.8,14,9.4,27.3,21.5,38.1s29.7,19,54.5,22.9c62.1,9.8,104.9-12,147.6-36.3
      c42.6-24.3,85-50.9,146.2-50.9c97.9,0,127.9,51.9,130.6,77.7"/>
    </svg>
  `,
  1: `
    <svg class="svg-stroke" width="100%" height="100%" viewBox="0 0 504 143" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path class="path" stroke="black" d="M457.1,266.1c-32.9-24.2-122.8-63-219.4-26.2c-60.1,22.9-117.7,18.1-161-1.7S4.4,183.7,1.3,146.9
        c-2.5-29.5,5.7-60,16.9-86.1C29.5,34.6,43.8,12.9,53.5,0.9"/>
      <path class="mask" stroke="white" d="M457.1,266.1c-32.9-24.2-122.8-63-219.4-26.2c-60.1,22.9-117.7,18.1-161-1.7S4.4,183.7,1.3,146.9
        c-2.5-29.5,5.7-60,16.9-86.1C29.5,34.6,43.8,12.9,53.5,0.9"/>
    </svg>
  `,
  2: `
    <svg class="svg-stroke" width="100%" height="100%" viewBox="0 0 513 292" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path class="path" stroke="black" d="M2.3,291.3c-1.7-3.4-2.2-8.5-0.1-15.4c2.1-6.8,6.7-15.3,15-25.2c16.7-19.8,48.4-45.4,104.9-75.2
        c70.5-37.3,164.6-39,242.6-39.2c39-0.1,73.9,0.1,99.9-3.6c13-1.8,23.7-4.7,31.6-9c7.9-4.4,13-10.3,14.4-18.3
        c9.3-50.5-23.8-90.8-41.5-104.6"/>
      <path class="mask" stroke="white" d="M2.3,291.3c-1.7-3.4-2.2-8.5-0.1-15.4c2.1-6.8,6.7-15.3,15-25.2c16.7-19.8,48.4-45.4,104.9-75.2
        c70.5-37.3,164.6-39,242.6-39.2c39-0.1,73.9,0.1,99.9-3.6c13-1.8,23.7-4.7,31.6-9c7.9-4.4,13-10.3,14.4-18.3
        c9.3-50.5-23.8-90.8-41.5-104.6"/>
    </svg>
  `,
  3: `
  <svg  class="svg-stroke" width="100%" height="100%" viewBox="0 0 217 202" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="path" stroke="black" d="M1.3,201.5C20,190.4,58.1,154.3,60.2,99.1C61.6,64.9,76,37.7,94.7,20.6s41.8-24,60.4-17.9
    C170,7.6,183.2,18,193.6,29c10.5,11,18.1,22.5,22,29.8"/>
    <path class="mask" stroke="white" d="M1.3,201.5C20,190.4,58.1,154.3,60.2,99.1C61.6,64.9,76,37.7,94.7,20.6s41.8-24,60.4-17.9
    C170,7.6,183.2,18,193.6,29c10.5,11,18.1,22.5,22,29.8"/>
  </svg>
  `
}

const clamp = (number: number, min: number, max: number) => {
  return Math.max(min, Math.min(number, max))
}

const DesktopSteps = ({ Items, backgroundColor }: { Items: any[]; backgroundColor: string }) => {
  useEffect(() => {
    console.log('DESKTOP STEPS...')
    // const stepsSection: any = document.getElementById("steps-section");
    // const sections = document.getElementsByClassName('step-item-wrapper');
    const stepsMegaParent: any = document.getElementsByClassName('steps-mega-parent')[0]

    const sections = document.getElementsByClassName('svg-wrapper') // all svg line holding wrappers
    const svgLines = document.getElementsByClassName('svg-stroke') // all svg lines of inside above wrappers

    /**
     * setting initial properties of svg paths and paths used to mask the original svg
     */
    for (let j = 0; j < svgLines.length; j++) {
      const relatedPath: any = svgLines[j].getElementsByClassName('path')[0]
      const relatedPathMask: any = svgLines[j].getElementsByClassName('mask')[0]
      const len = relatedPath.getTotalLength()

      relatedPath.style.strokeDasharray = len
      relatedPath.style.strokeDashoffset = len

      relatedPathMask.style.strokeDasharray = 10
      relatedPathMask.style.strokeWidth = 5
      relatedPathMask.style.stroke = backgroundColor
    }

    const drawLines = () => {
      const windowBottom = document.documentElement.scrollTop + document.documentElement.clientHeight

      for (let i = 0; i < sections.length; i++) {
        const section: any = sections[i]
        const secTopPointInDoc = section.offsetTop + section.parentElement?.parentElement?.offsetTop
        // + stepsMegaParent.parentElement.offsetTop
        // const secTopPointInDoc = section.offsetTop
        // const secTopPointInDoc = stepsSection.offsetTop;
        console.log(`SECTION RATIO - SECTION ${i} windowBottom`, windowBottom)
        console.log(`SECTION RATIO - SECTION ${i} section.offsetTop`, section.offsetTop)
        console.log(`SECTION RATIO - SECTION ${i} stepsMegaParent.offsetTop`, stepsMegaParent.offsetTop)
        console.log(`SECTION RATIO - SECTION ${i} secTopPointInDoc`, secTopPointInDoc)
        console.log(`SECTION RATIO - SECTION ${i} diff above`, windowBottom - secTopPointInDoc)
        console.log(`SECTION RATIO - SECTION ${i} sec offsetHeight`, section.offsetHeight)

        // console.log(`SECTION RATIO - SECTION ${i}`, document.documentElement.scrollTop);
        // const ratio = clamp((windowBottom - secTopPointInDoc) / stepsSection.offsetHeight, 0, 1)

        const ratio = clamp((windowBottom - secTopPointInDoc) / section.offsetHeight, 0, 1)
        console.log(`SECTION RATIO - SECTION ${i} ratio`, ratio)

        const relatedSvg = svgLines[i]
        const relatedPath: any = relatedSvg?.getElementsByClassName('path')[0]
        const pathLength = relatedPath?.getTotalLength()
        const drawLength = pathLength * ratio * ratio
        console.log('DRAWLENGTH', drawLength, pathLength)
        relatedPath.style.strokeDashoffset = pathLength - drawLength
        console.log('-----------------------------------------------------------------')
      }
    }

    window.addEventListener('scroll', drawLines)
    return () => {
      console.log('unregister scroll for desktop')
      window.removeEventListener('scroll', drawLines)
    }
  }, [])

  const getSvgPath = (isRight: boolean, index: number) => {
    if (index + 1 === Items.length && Items.length !== 1) {
      const path = svgPaths[3]
      if (!isRight) {
        return null
      }
      return (
        <div
          className="z-index-1 svg-wrapper desktop-svg-wrapper-end-pos absolute hidden w-1/4 sm:block"
          dangerouslySetInnerHTML={{ __html: path }}
        ></div>
      )
    }
    if (isRight) {
      const path = svgPaths[2]
      return (
        <div
          className="z-index-1 svg-wrapper desktop-svg-wrapper-mid-pos absolute hidden w-1/2 sm:block"
          dangerouslySetInnerHTML={{ __html: path }}
        ></div>
      )
    }
    const arr = [0, 1]
    const randomValue = Math.floor(Math.random() * 1)
    const selectedIndex: any = arr[randomValue]
    const path = svgPaths[selectedIndex]
    return (
      <div
        className="z-index-1 svg-wrapper desktop-svg-wrapper-start-pos absolute hidden w-1/2 sm:block"
        dangerouslySetInnerHTML={{ __html: path }}
      ></div>
    )
  }

  const renderStepItems = () => {
    return Items.map((stepItem, i) => {
      let isRight = false
      if ((i + 1) % 2 === 0) isRight = true

      return (
        <div
          className={`relative w-full sm:flex sm:flex-row 
            ${isRight ? 'justify-content-end' : 'justify-content-start'}
            ${i != 0 ? '-mt-6' : ''}
            step-item-wrapper
            `}
          key={i}
        >
          <div
            className={`w-11/12 ${
              isRight ? 'margin-right-adjustment' : 'margin-left-adjustment'
            } mx-auto my-0 sm:w-1/2`}
          >
            <StepItem
              builderImage={stepItem.builderImage}
              cloudinaryImage={stepItem.cloudinaryImage}
              imgAltText={stepItem.imgAltText}
              prominentText={stepItem.prominentText}
              prominentTextColor={stepItem.prominentTextColor}
              descriptionText={stepItem.descriptionText}
              descriptionTextColor={stepItem.descriptionTextColor}
              key={`${stepItem.prominentText}-${i}`}
              isMobile={false}
            />
            {getSvgPath(isRight, i)}
          </div>
        </div>
      )
    })
  }

  return <>{renderStepItems()}</>
}

export default DesktopSteps
